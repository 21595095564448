<template>
  <div>
    <b-form-group
      label="Tutar"
      label-for="amount"
    >
      <validation-provider
        #default="{ errors }"
        name="Tutar"
        rules="required"
      >
        <b-input-group append="TL">
          <cleave
            id="amount"
            v-model="dataItem.amount"
            class="form-control"
            :raw="false"
            :options="options.number"
            placeholder="Tutar"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import {
  BFormGroup, BInputGroup,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import Cleave from 'vue-cleave-component'

export default {
  name: 'CurrentAccountLineAmount',
  components: {
    BInputGroup, ValidationProvider, BFormGroup, Cleave,
  },
  data() {
    return {
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['currentAccountLines/dataItem']
    },
  },
}
</script>
