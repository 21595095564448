<template>
  <div>
    <b-form-group
      label="Ödeme Tarihi"
      label-for="pdate"
    >
      <validation-provider
        #default="{ errors }"
        name="Ödeme Tarihi"
        rules="required"
      >
        <b-form-datepicker
          id="pdate"
          v-model="dataItem.pdate"
          v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import { BFormGroup, BFormDatepicker } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'CurrentAccountLinepDate',
  components: { ValidationProvider, BFormGroup, BFormDatepicker },
  computed: {
    dataItem() {
      return this.$store.getters['currentAccountLines/dataItem']
    },
  },
}
</script>
