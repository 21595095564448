<template>
  <div>
    <b-row>
      <b-col>
        <current-account-line-content />
      </b-col>
      <b-col>
        <current-account-line-amount />
      </b-col>
      <b-col>
        <current-account-line-type-card />
      </b-col>
      <b-col
        v-if="dataItem.collection"
      >
        <current-account-linep-date />
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <current-account-line-collection-check />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import CurrentAccountLineContent from '@/views/CurrentAccounts/Lines/Elements/Content.vue'
import CurrentAccountLineAmount from '@/views/CurrentAccounts/Lines/Elements/Amount.vue'
import CurrentAccountLineTypeCard from '@/views/CurrentAccounts/Lines/Elements/TypeCard.vue'
import CurrentAccountLineCollectionCheck from '@/views/CurrentAccounts/Lines/Elements/CollectionCheckCard.vue'
import CurrentAccountLinepDate from '@/views/CurrentAccounts/Lines/Elements/pdateCard.vue'

export default {
  name: 'CurrentAccountLineForm',
  components: {
    CurrentAccountLinepDate,
    CurrentAccountLineCollectionCheck,
    CurrentAccountLineTypeCard,
    CurrentAccountLineAmount,
    CurrentAccountLineContent,
    BRow,
    BCol,
  },
  computed: {
    dataItem() {
      return this.$store.getters['currentAccountLines/dataItem']
    },
  },
}
</script>
