<template>
  <div>
    <b-card title="İşlem Güncelle">
      <validation-observer ref="simpleRules">
        <current-account-line-form />
      </validation-observer>
    </b-card>
    <b-row>
      <b-col>
        <b-button
          variant="primary"
          :disabled="lineData.submitButton"
          @click="submitForm"
        >
          <feather-icon icon="SaveIcon" />
          Kaydet
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CurrentAccountLineForm from '@/views/CurrentAccounts/Lines/Components/Lineform.vue'

export default {
  name: 'CurrentLinesAdd',
  components: {
    CurrentAccountLineForm,
    ValidationObserver,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    lineData() {
      return this.$store.getters['currentAccountLines/dataItem']
    },
    currentAccount() {
      return this.$store.getters['currentAccounts/dataItem']
    },
    saveData() {
      return this.$store.getters['currentAccountLines/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      this.lineData.submitButton = false
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.$router.push(`/current-accounts/view/${this.lineData.id_current_accounts}`)
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.$store.commit('currentAccountLines/RESET_DATA_ITEM')
    this.getData()
    localize('tr')
  },
  methods: {
    getData() {
      this.$store.dispatch('currentAccountLines/getDataItem', this.$route.params.id)
    },

    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.lineData.submitButton = true
          this.$store.dispatch('currentAccountLines/saveData', this.lineData)
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      }, {
        position: 'bottom-center',
      })
    },
  },
}
</script>
