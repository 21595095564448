<template>
  <div>
    <b-form-group
      label="İşlem Türü"
      label-for="type"
    >
      <validation-provider
        #default="{ errors }"
        name="İşlem Türü"
        rules="required"
      >
        <v-select
          id="type"
          v-model="dataItem.type"
          :options="types"
          label="title"
          :reduce="type => type.id"
          placeholder="Seçiniz"
          @input="collectionControl"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  name: 'CurrentAccountLineTypeCard',
  components: { ValidationProvider, BFormGroup, vSelect },
  data() {
    return {
      types: [
        {
          id: '0',
          title: 'Borçlandırma',
        },
        {
          id: '1',
          title: 'Ödeme / Tahsilat',
        },
      ],
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['currentAccountLines/dataItem']
    },
  },
  methods: {
    collectionControl() {
      if (this.dataItem.type) {
        this.dataItem.collection = 0
      }
    },
  },
}
</script>
