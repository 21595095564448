<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group
          label-for="collections"
        >
          <b-form-checkbox
            v-model="dataItem.collection"
            :disabled="dataItem.type !== '0'"
            switch
            value="1"
            @input="collectionControl"
          >
            Ödeme Takvimine Ekle
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <template v-if="dataItem.collection">
        <b-col
          cols="12"
          class="mb-1"
        >
          <hr>
          <div class="font-weight-bold">
            Ödeme Hatırlatıcı
          </div>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-checkbox
            v-model="dataItem.partial"
            :disabled="dataItem.type !== '0' && !dataItem.collection"
            switch
            value="1"
          >
            Kısmi ödeme yapılabilsin
          </b-form-checkbox>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-checkbox
            v-model="dataItem.alert1"
            :disabled="!dataItem.collection"
            switch
            value="1"
          >
            1 Gün Öncesinde Hatırlat
          </b-form-checkbox>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-checkbox
            v-model="dataItem.alert3"
            :disabled="!dataItem.collection"
            switch
            value="1"
          >
            3 Gün Öncesinde Hatırlat
          </b-form-checkbox>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-checkbox
            v-model="dataItem.alert5"
            :disabled="!dataItem.collection"
            switch
            value="1"
          >
            5 Gün Öncesinde Hatırlat
          </b-form-checkbox>
        </b-col>
      </template>
    </b-row>

  </div>
</template>
<script>
import {
  BRow, BCol, BFormCheckbox, BFormGroup,
} from 'bootstrap-vue'

export default {
  name: 'CurrentAccountLineCollectionCheck',
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
  },
  computed: {
    dataItem() {
      return this.$store.getters['currentAccountLines/dataItem']
    },
  },
  methods: {
    collectionControl() {
      if (!this.dataItem.collection) {
        this.dataItem.partial = false
        this.dataItem.alert1 = false
        this.dataItem.alert3 = false
        this.dataItem.alert5 = false
      }
    },
  },
}
</script>
